import React from 'react';
import { useTranslation } from 'next-i18next';
import styles from './sass/Logout.module.scss';
import LogoutIcon from '../../assets/sidebar/logout.svg';
import ILogout from './interfaces/ILogout';

const Logout = ({ logoutHandler, styleType }: ILogout) => {
  const { t } = useTranslation('common');
  return (
    <div
      className={`${styles.logout} ${styleType ? styles[styleType] : ''}`}
      aria-hidden="true"
      onClick={() => logoutHandler()}
    >
      <div className={styles.logout_iconWrapper}>
        <LogoutIcon />
      </div>
      <p>
        { t('logout') }
      </p>
    </div>
  );
};

export default Logout;
