import React, { useCallback } from 'react';
import Cookies from 'js-cookie';
import { shallowEqual, useSelector } from 'react-redux';
import { NextRouter, useRouter } from 'next/router';
import Logout from '../LogoutButton/Logout';
import SidebarItem from './components/SidebarItem';
import ProfileInfo from './components/ProfileInfo';
import { resetState } from '../../store/profile/slice';
import IStore from '../../store/interfaces/IStore';
import { UserRole } from '../../models/user.model';
import CourseServices from '../../services/courseServices';
import { useAppDispatch } from '../../hooks/storeHook';
import useAsync from '../../hooks/useAsync';
import { TOKEN_HELPER } from '../../constants/helperConstants';
import { sidebarPoints } from '../../constants/sidebarPoints';
import styles from './sass/Sidebar.module.scss';
import ISidebar from './interfaces/ISidebar';

const getCourseList = (role: UserRole) => CourseServices.getCourses(role);

const Sidebar = ({ showAuthHandling }: ISidebar) => {
  const dispatch = useAppDispatch();
  const router:NextRouter = useRouter();
  const { isMobileMenuOpened, avatar, userInfo } = useSelector(({ home, profile, user }: IStore) => ({
    isMobileMenuOpened: home.isMobileMenuOpened,
    avatar: profile.avatar,
    userInfo: user,
  }), shallowEqual);
  const userRole = userInfo.roles[0]?.name;
  const courses = useAsync(useCallback(() => getCourseList(userRole), [userRole]));

  const logoutHandler = () => {
    Cookies.remove(TOKEN_HELPER.ACCESS);
    Cookies.remove(TOKEN_HELPER.REFRESH);
    dispatch(resetState());
    router.push('/login').catch(() => {});
  };

  return (
    <div className={`${styles.sidebar} ${isMobileMenuOpened ? styles.active : ''}`}>
      <ProfileInfo
        avatar={avatar}
        name={`${userInfo.firstName} ${userInfo.lastName}`}
        type={userInfo.roles[0]?.name}
      />
      <div className={styles.points}>
        {
          sidebarPoints.filter(({ roles }) => roles.includes(userInfo.roles[0]?.name)).map((point, index) => (
            <SidebarItem
              icon={point.icon}
              title={point.title}
              key={[point.title, index].join('_')}
              subItems={point.withSubItems ? courses.result?.data.data : null}
              link={point.link}
              disabled={point.disabled}
            />
          ))
        }
      </div>
      {showAuthHandling && <Logout logoutHandler={logoutHandler} />}
    </div>
  );
};

export default Sidebar;
