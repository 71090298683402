import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { AxiosError } from 'axios';
import { AxiosErrorResponse, AxiosStatus } from '../models/axios.model';

const useAsync = <T = string>(
  asyncFunction: () => Promise<T>,
  immediate = true,
) => {
  const [status, setStatus] = useState<AxiosStatus>(AxiosStatus.Idle);
  const [result, setResult] = useState<T | null>(null);
  const [error, setError] = useState<AxiosError<AxiosErrorResponse>>();

  const setResultHandler = useCallback((data: T | null) => setResult(data), []);

  const execute = useCallback(() => {
    setStatus(AxiosStatus.Pending);
    asyncFunction()
      .then((response: T) => {
        setResult(response);
        setStatus(AxiosStatus.Success);
      })
      .catch((err: AxiosError<AxiosErrorResponse>) => {
        setError(err);
        setStatus(AxiosStatus.Error);
      });
  }, [asyncFunction]);

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [immediate, execute]);

  return {
    execute,
    status,
    result,
    error,
    setResultHandler,
  };
};

export default useAsync;
