import { shallowEqual, useSelector } from 'react-redux';
import NotificationItem from './components/NotificationItem';
import INotification from '../../store/notifications/interfaces/INotification';
import { dismissNotification } from '../../store/notifications/slice';
import { useAppDispatch } from '../../hooks/storeHook';
import styles from './sass/Notifications.module.scss';

const Notifications = () => {
  const dispatch = useAppDispatch();
  const list = useSelector(({ notifications }: { notifications: INotification }) => (
    notifications.notificationList
  ), shallowEqual);

  const handleDismiss = (id: number) => {
    dispatch(dismissNotification(id));
  };

  return (
    <ul id="notification-container" className={styles.notifications}>
      {list.map(({ message, id, type }) => (
        <NotificationItem
          key={id}
          handleDismiss={() => handleDismiss(id)}
          message={message}
          type={type}
        />
      ))}
    </ul>
  );
};

export default Notifications;
