import { useCallback, useEffect, useRef } from 'react';

const useTimeout = (fn: () => void, ms: number) => {
  const ready = useRef(false);
  const timeout = useRef(undefined);
  const callback = useRef(fn);

  const isReady = useCallback(() => ready.current, []);

  const set = useCallback(() => {
    ready.current = false;

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      ready.current = true;
      callback.current();
    }, ms) as never;
  }, [ms]);

  const clear = useCallback(() => {
    ready.current = false;
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, []);

  useEffect(() => {
    callback.current = fn;
  }, [fn]);

  useEffect(() => {
    set();

    return clear;
  }, [ms, clear, set]);

  return [isReady, clear, set];
};

export default useTimeout;
