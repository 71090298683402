import React, { FC, useEffect, useState } from 'react';
import { sendBirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react';
import { GroupChannelHandler } from '@sendbird/chat/groupChannel';
import { SendbirdGroupChat } from '@sendbird/chat/lib/__definition';
import { useRouter } from 'next/router';
import ISendBirdComponentWrapper, { IMessage } from '../interfaces/ISendBirdComponentWrapper';
import MessageToast from './MessageToast';
import { CHAT_MESSAGE_DISPLAY_TIME } from '../../../constants/chatConstants';

const SendBirdComponentWrapper: FC<ISendBirdComponentWrapper> = ({ children }) => {
  const [messageToDisplay, setMessageToDisplay] = useState<IMessage | null>(null);
  const { query } = useRouter();
  const channelUrl = query?.channelUrl || null;
  const store = useSendbirdStateContext();
  const sdkInstance = sendBirdSelectors.getSdk(store);

  useEffect(() => {
    if (sdkInstance) {
      const channelHandler = new GroupChannelHandler({
        onMessageReceived: (channel, message) => {
          setMessageToDisplay({ ...message, classId: channel.customType.replace('class:', '') } as unknown as IMessage);
        },
      });
      (sdkInstance as SendbirdGroupChat)?.groupChannel?.addGroupChannelHandler('uuid', channelHandler);
    }
    return () => {
      if ((sdkInstance as SendbirdGroupChat)?.groupChannel) {
        (sdkInstance as SendbirdGroupChat)?.groupChannel?.removeGroupChannelHandler('uuid');
      }
    };
  }, [sdkInstance]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (messageToDisplay) {
      timeout = setTimeout(() => setMessageToDisplay(null), CHAT_MESSAGE_DISPLAY_TIME);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [messageToDisplay]);

  return (
    <>
      {messageToDisplay && channelUrl !== messageToDisplay.channelUrl ? (
        <MessageToast
          redirectLink={`/chat/${messageToDisplay.classId}`.concat(`?channelUrl=${messageToDisplay.channelUrl}`)}
          messageText={messageToDisplay.message}
          senderName={messageToDisplay.sender.nickname}
          senderAvatar={messageToDisplay.sender.plainProfileUrl}
        />
      ) : null}
      {children}
    </>
  );
};

export default SendBirdComponentWrapper;
