import { useTranslation } from 'next-i18next';
import { NextRouter, useRouter } from 'next/router';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { toggleMobileMenu } from '../../store/home/slice';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import LinkItem from '../Link/Link';
import SidebarItem from '../Sidebar/components/SidebarItem';
import IStore from '../../store/interfaces/IStore';
import { sidebarCoursePoints } from '../../constants/sidebarPoints';
import ArrowIcon from '../../assets/sidebar/arrow-elipse.svg';
import LampIcon from '../../assets/sidebar/lamp.svg';
import styles from './sass/CourseSidebar.module.scss';

const CourseSidebar = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const router:NextRouter = useRouter();
  const {
    isMobileMenuOpened, userInfo, currentCourse, currentCollegeSession,
  } = useSelector(({
    home, user, course, collegeSession,
  }: IStore) => ({
    isMobileMenuOpened: home.isMobileMenuOpened,
    currentCourse: course.currentCourse,
    userInfo: user,
    currentCollegeSession: collegeSession.currentCollegeSession,
  }), shallowEqual);
  const id = router.query.id || '';
  const sessionId = router.query.sessionId || '';
  const redirectHandler = () => {
    dispatch(toggleMobileMenu());
    router.push('/').catch(() => {});
  };

  const sessionIdQuery = sessionId ? `?sessionId=${sessionId.toString()}` : '';
  const courseOrSessionId = currentCourse?.classId || currentCollegeSession?.collegeId || '';
  const title = currentCourse?.courseSubject || currentCollegeSession?.collegeName || '';
  const name = currentCourse?.courseName || currentCollegeSession?.session || '';
  const locationName = currentCourse?.locationName || currentCollegeSession?.address || '';
  const startAt = currentCourse?.startAt || currentCollegeSession?.startAt || '';
  const endAt = currentCourse?.endAt || currentCollegeSession?.endAt || '';

  return (
    <div className={`${styles.sidebar} ${isMobileMenuOpened ? styles.active : ''}`}>
      <Button handleClick={redirectHandler} styleButton="sidebarButton">
        <ArrowIcon />
        <TextField styleType="courseSidebarButton" text={t('returnMainMenu')} />
      </Button>
      <LinkItem styleType="sidebarDashboard" url={`/dashboard/${courseOrSessionId}${sessionIdQuery}`}>
        <div className={styles.sideBarTitle}>
          <LampIcon />
          <TextField styleType="courseSidebarMain" text={title} />
        </div>
      </LinkItem>
      <div className={styles.sidebarInfo}>
        <div className={styles.sidebarInfoDescription}>
          <TextField styleType="courseSidebarDescription">{name}</TextField>
          <TextField styleType="courseSidebarDescription">{locationName}</TextField>
          <TextField styleType="courseSidebarDescription">
            {`${t('startDate')}: ${moment(startAt).format('Do MMM YYYY')}`}
          </TextField>
          <TextField styleType="courseSidebarDescription">
            {`${t('endDate')}: ${moment(endAt).format('Do MMM YYYY')}`}
          </TextField>
        </div>
      </div>
      <div className={styles.points}>
        {
          sidebarCoursePoints.filter(({ roles }) => roles.includes(userInfo.roles[0]?.name)).map((point, index) => (
            <SidebarItem
              icon={point.icon}
              title={point.title}
              key={[point.title, index].join('_')}
              link={point.withId
                ? `${point.link}${id.toString()}?sessionId=${sessionId.toString() || ''}`
                : `${point.link}`}
              isLight
              disabled={point.disabled}
            />
          ))
        }
      </div>
    </div>
  );
};

export default CourseSidebar;
