import Button from '../../Button/Button';
import TextField from '../../TextField/TextField';
import SuccessIcon from '../../../assets/dashboard/success-border.svg';
import CloseIcon from '../../../assets/dashboard/close-icon.svg';
import INotificationItem from '../interfaces/INotificationItem';
import useTimeout from '../../../hooks/useTimeout';
import styles from '../sass/NotificationItem.module.scss';
import { TypesOfNitifcation } from '../../../store/notifications/interfaces/INotification';

const NotificationItem = ({ message, handleDismiss, type = TypesOfNitifcation.SUCCESS }: INotificationItem) => {
  useTimeout(
    handleDismiss,
    4000,
  );

  return (
    <li className={`${styles.notification} ${type === TypesOfNitifcation.SUCCESS ? styles.success : styles.error}`}>
      <div className={styles.notificationLeft}>
        {
          type === TypesOfNitifcation.SUCCESS ? (<SuccessIcon />) : (<CloseIcon />)
        }
        <TextField
          text={message}
          styleType="notificationTitle"
        />
      </div>
      <div className={styles.notificationRight}>
        <Button handleClick={handleDismiss} styleButton="iconButton">
          <CloseIcon />
        </Button>
      </div>
    </li>
  );
};

export default NotificationItem;
