import React from 'react';
import Image from 'next/image';
import styles from '../sass/Sidebar.module.scss';
import IProfileInfo from '../interfaces/IProfileInfo';
import TextField from '../../TextField/TextField';
import AvatarDefault from '../../../assets/profile/default_avatar.svg';

const ProfileInfo = ({ avatar, name, type }: IProfileInfo) => (
  <div className={styles.profileInfo}>
    <div className={styles.userPicWrapper}>
      {avatar ? <Image src={avatar} width={82} height={82} alt="" /> : <AvatarDefault />}
    </div>
    <div>
      <TextField styleType="name" text={name} />
      <TextField styleType="type" text={type} />
    </div>
  </div>
);

export default ProfileInfo;
