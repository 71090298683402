import { useEffect } from 'react';
import { useRouter } from 'next/router';
import CourseSidebar from '../components/CourseSidebar/CourseSidebar';
import Header from '../components/Header/Header';
import Sidebar from '../components/Sidebar/Sidebar';
import Notifications from '../components/Notifications/Notifications';
import ILayout from './interfaces/ILayout';
import { getProfileAvatar } from '../store/profile/slice';
import { useAppDispatch } from '../hooks/storeHook';
import { checkRoute } from '../utils/helper';
import styles from './sass/Layout.module.scss';
import SendBirdCustomProvider from '../pages/SendBirdCustomProvider/SendBirdCustomProvider';

const Layout = ({
  children,
  isCourse = false,
  isDarkBackground = false,
  showAuthHandling = true,
  userData,
}: ILayout) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const hideElements = checkRoute(router.pathname);

  useEffect(() => {
    if (!hideElements) {
      dispatch(getProfileAvatar()).catch(() => {});
    }
  }, [hideElements, dispatch]);

  return (
    <div className={`
      ${styles.container} 
      ${isDarkBackground ? styles.containerBackground : ''} 
      ${hideElements ? styles.elementsHidden : ''}`}
    >
      {!hideElements
        && (
          <Header
            showAuthHandling={showAuthHandling}
            addBgClass={`${isDarkBackground ? styles.containerBackground : styles.whiteBackground}`}
          />
        )}
      <SendBirdCustomProvider initialUserData={userData}>
        <div className={styles.wrapper}>
          {!hideElements && (isCourse ? <CourseSidebar /> : <Sidebar showAuthHandling={showAuthHandling} />)}
          <main className={`${hideElements ? styles.forms : ''} ${styles.main}`}>
            {children}
          </main>
        </div>
      </SendBirdCustomProvider>
      <Notifications />
      <div id="modal-container" />
    </div>
  );
};

export default Layout;
