export enum AxiosStatus {
  Idle = 'idle',
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}

export interface AxiosErrorResponseData {
  message: Array<string>;
}

export interface AxiosErrorResponse {
  message: string
  error: AxiosErrorResponseData
}
