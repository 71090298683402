import { UserRole } from '../models/user.model';
import DashboardIcon from '../assets/sidebar/dashboard.svg';
import CoursesIcon from '../assets/sidebar/courses.svg';
import TimetableIcon from '../assets/sidebar/timetable.svg';
import InformationIcon from '../assets/sidebar/information.svg';
import StudentIcon from '../assets/sidebar/student.svg';
import PeopleIcon from '../assets/sidebar/people.svg';
import BookSearchIcon from '../assets/sidebar/book-search.svg';
import CalendarIcon from '../assets/sidebar/calendar.svg';
import MessagesIcon from '../assets/sidebar/message.svg';
import ProfileIcon from '../assets/sidebar/profile.svg';

export const sidebarCoursePoints = [
  {
    title: 'courseInformation',
    icon: <InformationIcon />,
    link: '/information/',
    disabled: false,
    roles: [UserRole.Tutor, UserRole.Participant],
    withId: true,
  },
  {
    title: 'timetable',
    icon: <TimetableIcon />,
    link: '/time-table/',
    withId: true,
    disabled: false,
    roles: [UserRole.Mentor],
  },
  {
    title: 'participantEvaluations',
    icon: <PeopleIcon />,
    link: '/evaluations/',
    disabled: false,
    withId: true,
    roles: [UserRole.Tutor],
  },
  {
    title: 'yourStudents',
    icon: <StudentIcon />,
    withId: true,
    link: '/students/',
    disabled: false,
    roles: [UserRole.Tutor, UserRole.Mentor],
  },
  {
    title: 'attendance',
    icon: <CalendarIcon />,
    withId: true,
    link: '/attendance/',
    disabled: false,
    roles: [UserRole.Tutor, UserRole.Mentor],
  },
  {
    title: 'incidentReportForm',
    icon: <BookSearchIcon />,
    link: '/incident-report/',
    disabled: false,
    withId: true,
    roles: [UserRole.Tutor, UserRole.Mentor],
  },
  {
    title: 'messages',
    icon: <MessagesIcon />,
    link: '/chat/',
    disabled: false,
    withId: true,
    roles: [UserRole.Mentor, UserRole.Tutor, UserRole.Participant],
  },
];

export const sidebarPoints = [
  {
    title: 'dashboard',
    icon: <DashboardIcon />,
    link: '/',
    disabled: false,
    roles: [UserRole.Mentor, UserRole.Tutor, UserRole.Participant],
  },
  {
    title: 'yourCourses',
    icon: <CoursesIcon />,
    disabled: false,
    withSubItems: true,
    roles: [UserRole.Tutor, UserRole.Participant],
  },
  {
    title: 'yourProfile',
    icon: <ProfileIcon />,
    link: '/profile',
    roles: [UserRole.Participant],
    disabled: false,
  },
];
