import React, { FC, useEffect } from 'react';
import { SendBirdProvider } from '@sendbird/uikit-react';
import { useSelector } from 'react-redux';
import ISendBirdCustomProvider from './interfaces/ISendBirdCustomProvider';
import SendBirdComponentWrapper from './components/SendBirdComponentWrapper';
import { useAppDispatch } from '../../hooks/storeHook';
import { getChatUserInfo } from '../../store/user/slice';
import IStore from '../../store/interfaces/IStore';

const SendBirdCustomProvider: FC<ISendBirdCustomProvider> = ({ children, initialUserData }) => {
  const dispatch = useAppDispatch();
  const fetchedUserData = useSelector((state:IStore) => state.user.userChatData);
  const userData = initialUserData || fetchedUserData;

  useEffect(() => {
    if (!userData) {
      dispatch(getChatUserInfo()).catch(() => {});
    }
  }, [dispatch, userData]);

  return (
    userData ? (
      <SendBirdProvider
        appId={process.env.NEXT_PUBLIC_SENDBIRD_APP_ID || ''}
        accessToken={userData.access_token}
        userId={userData.user_id}
        disableUserProfile
      >
        <SendBirdComponentWrapper>
          {children}
        </SendBirdComponentWrapper>
      </SendBirdProvider>
    ) : children
  );
};

export default SendBirdCustomProvider;
