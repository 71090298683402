import React, { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import LinkItem from '../../Link/Link';
import TextField from '../../TextField/TextField';
import ISidebarItems from '../interfaces/ISidebarItems';
import { toggleMobileMenu } from '../../../store/home/slice';
import { YOUR_COURSES } from '../../../constants/sidebar';
import API_ROUTES from '../../../constants/apiRoutes';
import ArrowIcon from '../../../assets/sidebar/arrow.svg';
import LampIcon from '../../../assets/sidebar/pointLamp.svg';
import styles from '../sass/SidebarItem.module.scss';

const SidebarItem = ({
  icon,
  title,
  subItems,
  disabled,
  isLight = false,
  link = '/',
}: ISidebarItems) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const [active, setActive] = useState(false);
  const [activeMenu, setActiveMenu] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveMenu(router.asPath === link);
  }, [link, router.asPath]);

  const clickItemHandler = () => {
    setActive(!active);
    dispatch(toggleMobileMenu());
  };

  return (
    <div className={`${styles.wrapperMenu}  ${disabled ? styles.disabled : ''}`}>
      <div
        className={`
          ${styles.sidebar_item}
          ${isLight ? styles.sidebar_item_light : ''}
          ${active ? styles.active : ''}
          ${disabled ? styles.disabled : ''}
        `}
        onClick={clickItemHandler}
        aria-hidden
      >
        <div className={`${styles.itemIconWrapper} ${activeMenu ? styles.activeItem : ''}`}>
          {icon}
        </div>
        {title === YOUR_COURSES ? (
          <TextField text={t(title)} styleType="sidebarItem_title" />
        ) : (
          <LinkItem
            url={link}
            as={link}
            styleType={activeMenu
              ? `sidebarItem_title_active${isLight ? '_light' : ''}`
              : `sidebarItem_title${isLight ? '_light' : ''}`}
            text={t(title)}
          />
        )}
        {
          subItems && (<ArrowIcon className={styles.arrowIcon} />)
        }
      </div>
      {
        subItems && (
          <div className={`${styles.subItemList} ${active ? styles.active : ''}`}>
            {subItems.map((subItem, index) => (
              <LinkItem
                key={[subItem.courseName, index].join('_')}
                url={`${API_ROUTES.DASHBOARD}${subItem.classId}`}
                styleType="subItemSidebar"
              >
                <div className={styles.itemIconWrapper}>
                  <LampIcon />
                </div>
                <div>
                  <TextField text={subItem.courseName} styleType="sidebarSubItem_title" />
                  <TextField text={subItem.courseSubject} styleType="sidebarSubItem_description" />
                </div>
              </LinkItem>
            ))}
          </div>
        )
      }
    </div>
  );
};

export default SidebarItem;
