import React, { FC } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import TextField from '../../../components/TextField/TextField';
import IMessageToast from '../interfaces/IMessageToast';

import styles from '../sass/MessageToast.module.scss';

const MessageToast: FC<IMessageToast> = ({
  messageText, senderName, senderAvatar, redirectLink,
}) => (
  <Link href={redirectLink} className={styles.container}>
    <Image
      className={styles.image}
      src={senderAvatar}
      width={70}
      height={70}
      alt="message"
    />
    <div className={styles.textContainer}>
      <TextField text={senderName} />
      <TextField text={messageText} />
    </div>
  </Link>
);

export default MessageToast;
