export const FILES_LIST = 'pdf&jpeg&doc&docx&xls&xlsx&zip&rar&mp3&mov&mp4&avi&png&jpg&svg&txt';

export const FILE_LIMIT = 20;

export const MESSAGE_HANDLER_ID = 'GLOBAL_MESSAGE_EVENT';

export const MESSAGE_INITIAL_READ_COUNT = 0;

export const DEFAULT_AVATAR_SIZE = 9674;

export const CHAT_MESSAGE_DISPLAY_TIME = 3000;

export enum ModalHeight {
  Mobile = 350,
  Desktop = 400,
}
