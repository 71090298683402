import React from 'react';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { NextRouter, useRouter } from 'next/router';
import LinkItem from '../Link/Link';
import styles from './sass/Header.module.scss';
import LogoIcon from '../../assets/header/logo.svg';
import BurgerIcon from '../../assets/header/burger.svg';
import Button from '../Button/Button';
import { toggleMobileMenu } from '../../store/home/slice';
import Logout from '../LogoutButton/Logout';
import { resetState } from '../../store/profile/slice';
import { TOKEN_HELPER } from '../../constants/helperConstants';
import IHeader from './interfaces/IHeader';

const Header = ({ addBgClass, showAuthHandling = true }: IHeader) => {
  const dispatch = useDispatch();
  const router:NextRouter = useRouter();

  const logoutHandler = () => {
    Cookies.remove(TOKEN_HELPER.ACCESS);
    Cookies.remove(TOKEN_HELPER.REFRESH);
    dispatch(resetState());
    router.push('/login').catch(() => {});
  };

  return (
    <div className={`${styles.headerWrapper} ${addBgClass}`}>
      <header className={styles.header}>
        <LinkItem url="/" isUseDefaultClass={false}>
          <LogoIcon className={styles.logo} />
        </LinkItem>
        <div className={styles.buttonWrapper}>
          <Button
            handleClick={() => dispatch(toggleMobileMenu()) as never}
            styleButton="burgerButton"
          >
            <BurgerIcon />
          </Button>
          {showAuthHandling && (
            <Logout
              styleType="headerLogout"
              logoutHandler={logoutHandler}
            />
          )}
        </div>
      </header>
    </div>
  );
};

export default Header;
